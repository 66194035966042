import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TrashIcon } from '../../../../assets';
import { ACTION_TYPES } from '../../../../config/constants';
import { Confirm } from '../../../../context/confirm/confirm';
import type { TInfSettingsAction } from '../../../../types/action';
import { GenericDropdown } from '../../../inputs/GenericDropdown';
import PlatformDropdown from '../../../inputs/PlatformDropdown';
import { TextInput } from '../../../inputs/TextInput';
import { Toggle } from '../../../inputs/Toggle';

export default function ActionSettings({
  action,
  updateAction,
  deleteAction,
  control,
  index,
}: {
  action: TInfSettingsAction;
  updateAction: (key: string, val: any) => void;
  deleteAction: () => void;
  control: any;
  index: number;
}) {
  const { t } = useTranslation('common');
  const [open, setOpen] = useState(false);

  return (
    <div className="flex flex-row gap-5">
      <Confirm
        open={open}
        onConfirm={() => deleteAction()}
        setOpen={setOpen}
        type="delete"
        title={t('global.deleteTitle')}
        subtitle={t('global.deleteSubtitle')}
        confirmText={t('global.delete')}
        cancelText={t('global.cancel')}
      />

      <div className="flex flex-col size-full gap-1.5">
        <div className="flex lg:flex-row flex-col gap-1.5">
          <PlatformDropdown
            disabled={action.disabled}
            platformSelected={action.platform}
            onChange={(val) => {
              updateAction('platform', val);
            }}
          />
          <TextInput
            disabled={action.disabled}
            type="number"
            placeholder={t('singleCampaign.numberOfPieces')}
            selectedValue={action.number_of_action}
            onBlur={(e) => {
              updateAction('number_of_action', Number(e.target.value));
            }}
          />
          <GenericDropdown
            control={control}
            options={ACTION_TYPES[action.platform].map((type: string) => ({
              value: type,
              label: t(`actionType.${type}`),
            }))}
            height="h-[41px]"
            name={`actions.${index}.type`}
            onChangeManual={(value) => {
              updateAction('type', value.value);
            }}
          />
          <Toggle
            label={t('singleCampaign.validateScript')}
            disabled={action.disabled}
            value={action.hasScript}
            onChange={() => {
              updateAction('hasScript', !action.hasScript);
            }}
          />
          <Toggle
            disabled={action.disabled}
            label={t('singleCampaign.validatePreview')}
            value={action.hasPreview}
            onChange={() => {
              updateAction('hasPreview', !action.hasPreview);
            }}
          />
          <Toggle
            label={t('singleCampaign.internalValidation')}
            disabled={action.disabled}
            value={action.internal_validation}
            onChange={() => {
              updateAction(
                action.internal_validation
                  ? 'internal_validation'
                  : 'external_validation',
                !action.internal_validation,
              );
            }}
          />
        </div>

        <TextInput
          type="text"
          placeholder={t('singleCampaign.descriptionActionPlaceholder')}
          maxLength={500}
          selectedValue={action.description}
          onBlur={(e) => {
            updateAction('description', e.target.value);
          }}
        />
      </div>
      <div
        className={`flex lg:items-center lg:justify-center lg:mt-0 mt-4 cursor-pointer`}
        onClick={() => setOpen(true)}
      >
        <TrashIcon className="size-[15px] text-red-status" />
      </div>
    </div>
  );
}
