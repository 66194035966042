/* eslint-disable @typescript-eslint/no-shadow */
import { useMutation, useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useOutletContext } from 'react-router-dom';

import { CheckBoxReferralIcon } from '../../../assets';
import { RefetchLateral } from '../../../context/refetchLateral/refetchProvider';
import { UPDATE_INFLUENCER_SETTINGS } from '../../../graphql/campaign/mutation';
import { GET_INFLUENCER_SETTINGS } from '../../../graphql/campaign/query';
import { useNotification } from '../../../hooks/useNotification';
import { usePrompt } from '../../../hooks/usePrompt';
import type { TInfluencerSettingsForm } from '../../../types';
import { Button } from '../../common/Button';
import { CampaignPriceSections } from '../settings/CampaignPriceSection';
import ActionSettings from '../settings/influencer/ActionSettings';
import ReferralCode from '../settings/influencer/ReferralCode';

export const SingleCampaignSettingsInfluencerTab = () => {
  const [campaignId, influencerId]: [string, string] = useOutletContext();
  const { refetchLateral, refetchTabs } = useContext(RefetchLateral);
  const { t } = useTranslation('common');
  const [loadingShow, setLoading] = useState(true);
  const [loadingSend, setLoadingSend] = useState(false);
  const {
    control,
    reset,
    watch,
    formState: { dirtyFields },
    setValue,
  } = useForm<TInfluencerSettingsForm>();

  const { status } = watch();

  const {
    fields: actions,
    append: addAction,
    remove: removeAction,
    update: updateAction,
  } = useFieldArray({ control, name: 'actions', keyName: 'rowId' });

  const {
    fields: referrals,
    append: addReferral,
    remove: removeReferral,
    update: updateReferral,
  } = useFieldArray({ control, name: 'referrals', keyName: 'rowId' });

  const { refetch, loading: loadingInfo } = useQuery(GET_INFLUENCER_SETTINGS, {
    variables: {
      influencerId,
      campaignId,
    },
    fetchPolicy: 'network-only', // Used for first execution
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const info: TInfluencerSettingsForm =
        data.influencerCampaignSettings.data;
      reset(
        {
          ...info,
          influencer_variable_type: info.influencer_variable_type
            ? info.influencer_variable_type
            : '',
          actions: info.actions.map((a) => ({ ...a, disabled: true })),
        },
        {
          keepDirty: false,
        },
      );
      setLoading(false);
    },
  });
  const [updateInfluencerSettings, { loading }] = useMutation(
    UPDATE_INFLUENCER_SETTINGS,
  );
  const { notifySaveSuccess, notifySaveError } = useNotification();

  const handleNewReferral = (type: string) => {
    addReferral({
      id: Math.floor(Math.random() * 1000).toString(),
      type,
      referral: '',
      platforms: '',
    });
  };
  const handleNewAction = () => {
    addAction({
      id: Math.floor(Math.random() * 1000).toString(),
      type: 'Dedicated_Video',
      platform: 'Youtube',
      description: '',
      number_of_action: 1,
      hasScript: true,
      hasPreview: true,
      disabled: false,
      total_currency: '€',
      influencer_currency: '€',
      internal_validation: true,
    });
  };
  const { notifyError } = useNotification();

  const handleSave = async (status?: string) => {
    setLoadingSend(!!status);
    if (status) {
      if (Number(watch('influencer_amount')) === 0) {
        notifyError(t('singleCampaign.influencerPriceRequired'));
        return;
      }
      if (actions.length === 0) {
        notifyError(t('singleCampaign.actionsRequired'));
        return;
      }
    }
    updateInfluencerSettings({
      variables: {
        influencerId,
        status,
        campaignId,
        influencerAmount: Number(watch('influencer_amount')),
        influencerAmountType: watch('influencer_amountType'),
        totalAmount: Number(watch('total_amount')),
        total_variable_amount: Number(watch('total_variable_amount')),
        influencer_variable_type: watch('influencer_variable_type'),
        influencer_variable_amount: Number(watch('influencer_variable_amount')),
        referrals: watch('referrals').map((r) => ({ ...r, rowId: undefined })),
        actions: watch('actions').map((action) => ({
          id: action.id,
          type: action.type,
          platform: action.platform,
          description: action.description,
          hasScript: action.hasScript,
          hasPreview: action.hasPreview,
          number_of_action: action.number_of_action,
          variable_type: action.variable_type || undefined,
          total_amount: action.total_amount
            ? Number(action.total_amount)
            : undefined,
          total_currency: action.total_currency,
          influencer_amount: action.influencer_amount
            ? Number(action.influencer_amount)
            : undefined,
          influencer_currency: action.influencer_currency,
          internal_validation: action.internal_validation,
        })),
      },
    })
      .then(() => {
        notifySaveSuccess();
        refetch();
        reset();
        refetchLateral();
        refetchTabs();
      })
      .catch(() => {
        notifySaveError();
      });
  };

  useEffect(() => {
    const type = watch('influencer_variable_type');
    if (type === 'No' || type === 'Per Action' || !type) {
      setValue('influencer_variable_amount', 0, { shouldDirty: false });
      setValue('total_variable_amount', 0, { shouldDirty: false });
    }
    if (type !== 'Per Action') {
      const newActions = actions.map((a) => {
        return { ...a, total_amount: 0, influencer_amount: 0 };
      });
      setValue('actions', newActions, { shouldDirty: false });
    }
  }, [watch('influencer_variable_type')]);

  useEffect(() => {
    reset({});
    setLoading(true);
  }, [influencerId, campaignId]);

  const deleteReferral = (i: number) => {
    removeReferral(i);
  };
  const deleteAction = (i: number) => {
    removeAction(i);
  };
  usePrompt({
    isDirty: Object.keys(dirtyFields).length > 0,
  });
  return (
    <>
      {!loadingInfo && !loadingShow && (
        <div className="pb-20">
          <CampaignPriceSections
            actions={actions}
            control={control}
            influencer_variable_type={watch('influencer_variable_type')}
            watch={watch}
          />
          <div className="flex lg:flex-row flex-col mt-5 gap-8">
            <div className="w-full flex flex-col">
              <span>{t('singleCampaign.referrerCode')}</span>
              <div className="flex flex-row w-full gap-8">
                <div className="w-full flex flex-col gap-1">
                  {referrals.map((referral, i) => {
                    if (referral.type === 'Code') {
                      return (
                        <ReferralCode
                          key={referral.id}
                          referral={referral}
                          updateReferral={(key: string, val: any) =>
                            updateReferral(i, {
                              ...referral,
                              [key]: val,
                            } as any)
                          }
                          deleteReferral={() => deleteReferral(i)}
                        />
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
              <button
                className="w-full flex items-center justify-center"
                onClick={() => handleNewReferral('Code')}
              >
                <div className="flex flex-row items-center justify-center mt-1 gap-1 border-b border-influentia-light-blue w-fit">
                  <CheckBoxReferralIcon />
                  <span className="text-[10px] text-influentia-light-blue font-medium">
                    {t('singleCampaign.addCode')}
                  </span>
                </div>
              </button>
            </div>
            <div className="w-full">
              <span>{t('singleCampaign.referrerLink')}</span>
              <div className="flex flex-row w-full gap-8">
                <div className="w-full flex flex-col gap-1">
                  {watch('referrals').map((referral, i) => {
                    if (referral.type === 'Link') {
                      return (
                        <ReferralCode
                          key={referral.id}
                          referral={referral}
                          updateReferral={(key: string, val: any) => {
                            updateReferral(i, {
                              ...referral,
                              [key]: val,
                            } as any);
                          }}
                          deleteReferral={() => deleteReferral(i)}
                        />
                      );
                    }
                    return null;
                  })}
                </div>
              </div>
              <div className="w-full flex items-center justify-center">
                <button onClick={() => handleNewReferral('Link')}>
                  <div className="flex flex-row items-center justify-center mt-1 gap-1 border-b border-influentia-light-blue w-fit">
                    <CheckBoxReferralIcon />
                    <span className="text-[10px] text-influentia-light-blue font-medium">
                      {t('singleCampaign.addLink')}
                    </span>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <div className="mt-5">
            <span>{t('global.actions')}</span>
            <div className="flex flex-col gap-2">
              {actions.map((action, i) => (
                <ActionSettings
                  key={action.id}
                  control={control}
                  action={action}
                  updateAction={(key: string, val: any) => {
                    const currentAction = watch(`actions.${i}`);
                    updateAction(i, {
                      ...currentAction,
                      [key]: val,
                    } as any);
                  }}
                  deleteAction={() => deleteAction(i)}
                  index={i}
                />
              ))}
            </div>
            <div className="w-full flex items-center justify-center">
              <button id="addAction" onClick={() => handleNewAction()}>
                <div className="flex flex-row items-center justify-center mt-1 gap-1 border-b border-influentia-light-blue w-fit">
                  <CheckBoxReferralIcon />
                  <span className="text-[10px] text-influentia-light-blue font-medium">
                    {t('singleCampaign.addAction')}
                  </span>
                </div>
              </button>
            </div>
          </div>
          <div className="mt-5 lg:items-end flex lg:justify-end justify-center w-full flex-row gap-3">
            <Button
              id="saveInfluencerSettings"
              style="light-blue"
              onClick={() => handleSave()}
              loading={loading && !loadingSend}
              text={t('singleCampaign.updateInfo')}
            />
            {status === 'not_send' && (
              <Button
                style="light-blue"
                onClick={() => handleSave('active')}
                loading={loading && loadingSend}
                text={t('singleCampaign.sendProposal')}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};
