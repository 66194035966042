import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TrashIcon } from '../../../assets';
import { ACTION_TYPES } from '../../../config/constants';
import { Confirm } from '../../../context/confirm/confirm';
import type { PlatformType } from '../../../types';
import type { TInfSettingsRecurrentAction } from '../../../types/action';
import { GenericDropdown } from '../../inputs/GenericDropdown';
import PlatformDropdown from '../../inputs/PlatformDropdown';
import { TextInput } from '../../inputs/TextInput';
import { Toggle } from '../../inputs/Toggle';

export default function ActionSettingsRecurrent({
  action,
  updateAction,
  deleteAction,
  control,
  index,
}: {
  action: TInfSettingsRecurrentAction;
  updateAction: (key: string, val: any) => void;
  deleteAction: () => void;
  control: any;
  index: number;
}) {
  const { t } = useTranslation('common');
  const {
    hasPreview,
    hasScript,
    description,
    number_of_action: numberAction,
  } = action;

  const [open, setOpen] = useState(false);

  return (
    <div className="flex flex-row gap-5">
      <Confirm
        open={open}
        onConfirm={() => deleteAction()}
        setOpen={setOpen}
        type="delete"
        title={t('global.deleteTitle')}
        subtitle={t('global.deleteSubtitle')}
        confirmText={t('global.delete')}
        cancelText={t('global.cancel')}
      />

      <div className="flex flex-col size-full gap-1.5">
        <div className="flex lg:flex-row flex-col gap-1.5">
          <PlatformDropdown
            platformSelected={action.platform}
            onChange={(val) => {
              updateAction('platform', val);
            }}
          />
          <TextInput
            type="number"
            placeholder={t('singleCampaign.numberOfPieces')}
            selectedValue={numberAction}
            onBlur={(event) => {
              updateAction('number_of_action', Number(event.target.value));
            }}
          />
          <GenericDropdown
            options={ACTION_TYPES[action.platform as PlatformType].map(
              (type: string) => ({
                value: type,
                label: t(`actionType.${type}`),
              }),
            )}
            height="h-[41px]"
            control={control}
            name={`actions.${index}.type`}
            onChangeManual={(value) => {
              updateAction('type', value.value);
            }}
          />
          <Toggle
            label={t('singleCampaign.validateScript')}
            value={action.hasScript}
            onChange={() => {
              updateAction('hasScript', !action.hasScript);
            }}
          />
          <Toggle
            label={t('singleCampaign.validatePreview')}
            value={action.hasPreview}
            onChange={() => {
              updateAction('hasPreview', !action.hasPreview);
            }}
          />
          <Toggle
            label={t('singleCampaign.internalValidation')}
            value={action.internal_validation}
            onChange={() => {
              updateAction('internal_validation', !action.internal_validation);
            }}
          />
        </div>

        <TextInput
          type="text"
          placeholder={t('singleCampaign.descriptionActionPlaceholder')}
          maxLength={500}
          selectedValue={description}
          onBlur={(event) => {
            updateAction('description', event.target.value);
          }}
        />
      </div>
      <div
        className={`flex lg:items-center lg:justify-center lg:mt-0 mt-4 cursor-pointer`}
        onClick={() => setOpen(true)}
      >
        <TrashIcon className="size-[15px] text-red-status" />
      </div>
    </div>
  );
}
